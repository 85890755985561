@use '../config' as *;
@use '_1_radio-switch.scss' as *;

/* -------------------------------- 

File#: _2_pricing-table
Title: Pricing Table
Descr: A table used to compare prices and features of different products
Usage: codyhouse.co/license

-------------------------------- */

.p-table__item {
  background-color: var(--color-bg-dark);
  border-radius: var(--radius-lg);
  box-shadow: var(--inner-glow);
  padding: var(--space-md);
  display: flex;
  flex-direction: column;
}

.p-table__item--popular {
  background-color: var(--color-bg-light);
  box-shadow: 0 0 0 2px var(--color-primary), var(--shadow-md);
}

.p-table__badge {
  font-size: var(--text-sm);
  background-color: var(--color-contrast-high);
  color: var(--color-bg);
  padding: var(--space-3xs) var(--space-2xs);
  vertical-align: middle;
  border-radius: var(--radius-md);
}

.p-table__price {
  span { // amount
    font-size: var(--text-3xl);
    font-weight: bold;
  }

  i { // month/year
    color: var(--color-contrast-low);
  }
}

.p-table__features {
  li {
    margin-bottom: var(--space-xs);
  }
}

// --has-switch
.p-table__switch {
  display: none;
}

.p-table--has-switch {
  .p-table__price-wrapper {
    position: relative;
    overflow: hidden;
  }

  .p-table__price {
    will-change: transform;
    transition: transform 0.3s, opacity 0.3s;
    transition-timing-function: var(--ease-out);

    &[data-transition-delay="2nd"] {
      transition-delay: 0.1s;
    }

    &[data-transition-delay="3rd"] {
      transition-delay: 0.2s;
    }
  }

  .p-table__price--month {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .p-table__price--year {
    pointer-events: none;
    opacity: 0;
    transform: translateY(100%);
  }
}

.p-table--yearly {
  .p-table__price--month {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-100%);
  }

  .p-table__price--year {
    pointer-events: auto;
    opacity: 1;
    transform: translateY(0);
  }
}