@use '../config' as *;
@use '_1_modal-window.scss' as *;

/* -------------------------------- 

File#: _2_full-screen-search
Title: Full Screen Search
Descr: A full-screen window w/ a search input element
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --fs-search-btn-size: 1.5em;
  --fs-search-border-bottom-width: 2px;
}

.full-screen-search {
  position: relative;
  font-size: var(--text-xl);
  background-image: linear-gradient(transparent calc(100% - var(--fs-search-border-bottom-width) * 2), var(--color-contrast-low) calc(100% - var(--fs-search-border-bottom-width) * 2), var(--color-contrast-low) calc(100% - var(--fs-search-border-bottom-width)), transparent var(--fs-search-border-bottom-width)); // border bottom
}

.full-screen-search__input {
  background: transparent; // reset
  width: 100%;
  padding: var(--space-sm) var(--space-xs);
  padding-right: calc(var(--space-xs) + var(--fs-search-btn-size));
  background-image: linear-gradient(transparent calc(100% - var(--fs-search-border-bottom-width) * 2), var(--color-primary) calc(100% - var(--fs-search-border-bottom-width) * 2), var(--color-primary) calc(100% - var(--fs-search-border-bottom-width)), transparent var(--fs-search-border-bottom-width)); // filling effect
  background-size: 0% 100%;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
    background-size: 100% 100%;
  }
}

.full-screen-search__input::-webkit-search-decoration,
.full-screen-search__input::-webkit-search-cancel-button,
.full-screen-search__input::-webkit-search-results-button,
.full-screen-search__input::-webkit-search-results-decoration { 
  display: none; 
}

.full-screen-search__btn {
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  right: var(--space-xs);
  height: var(--fs-search-btn-size);
  width: var(--fs-search-btn-size);
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    display: block;
    color: var(--color-white);
    font-size: 20px; // icon size
    transition: transform .2s;
  }

  &:hover {
    .icon {
      transform: scale(1.1);
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .modal--search {
    .full-screen-search__input {
      transition: background-size .4s var(--ease-out);
    }
  
    .full-screen-search__btn {
      transform: translateY(-30%) scale(0.6);
      opacity: 0;
      transition: opacity .4s, transform .4s var(--ease-out);
    }
  
    &.modal--is-visible {
      .full-screen-search__btn {
        transform: translateY(-50%) scale(1);
        opacity: 1;
      }
    }
  }
}