@use '../config' as *;

/* -------------------------------- 

File#: _1_countup
Title: CountUp
Descr: A widget to animate a numerical value by counting to it
Usage: codyhouse.co/license

-------------------------------- */

.countup {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
  opacity: 0; // hide countup before it is initialized
}

.countup--is-visible { // show countup once it has been initialized
  opacity: 1;
}

