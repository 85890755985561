@use '../config' as *;

/* -------------------------------- 

File#: _1_card
Title: Card
Descr: Container of information used as teaser for further content exploration
Usage: codyhouse.co/license

-------------------------------- */

.card {
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-ring), var(--shadow-sm);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.card__img-wrapper {
  img {
    display: block;
    width: 100%;
  }
}

// --link
.card--link {
  text-decoration: none;
  display: block;
  color: inherit;
  transition: 0.2s;

  &:hover {
    background-color: var(--color-bg-lighter);
    box-shadow: var(--inner-glow), var(--shadow-ring), var(--shadow-md);

    .card__img-wrapper::after {
      opacity: 1;
    }
  }

  .card__img-wrapper {
    position: relative;

    &::after { // overlay layer
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: alpha(var(--color-black), 0.6);
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
}

.card__link-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 64px;
  height: 64px;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  background-color: alpha(var(--color-white), 0.9);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(0.8);

  opacity: 0;
  transition: 0.2s;

  .icon {
    display: block;
    width: 32px;
    height: 32px;
    color: var(--color-black); // icon color
  }
}

.card--link:hover .card__link-icon-wrapper {
  opacity: 1;
  transform: scale(1);
}