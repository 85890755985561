@use '../config' as *;

/* -------------------------------- 

File#: _1_auto-sized-grid
Title: Auto Sized Grid
Descr: A grid layout based on CSS Grid where the columns are automatically created according to a min-width value
Usage: codyhouse.co/license

-------------------------------- */

.grid-auto-xs, .grid-auto-sm, .grid-auto-md, .grid-auto-lg, .grid-auto-xl {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(var(--col-min-width), 1fr)); // auto add new cols
}

.grid-auto-xs { --col-min-width: 8rem; }
.grid-auto-sm { --col-min-width: 10rem; }
.grid-auto-md { --col-min-width: 15rem; }
.grid-auto-lg { --col-min-width: 20rem; }
.grid-auto-xl { --col-min-width: 25rem; }

@each $breakpoint, $value in $breakpoints { 
  @include min-width(#{$breakpoint}) {
    .grid-auto-xs\@#{$breakpoint} { --col-min-width: 8rem; }
    .grid-auto-sm\@#{$breakpoint} { --col-min-width: 10rem; }
    .grid-auto-md\@#{$breakpoint} { --col-min-width: 15rem; }
    .grid-auto-lg\@#{$breakpoint} { --col-min-width: 20rem; }
    .grid-auto-xl\@#{$breakpoint} { --col-min-width: 25rem; }
  }
}