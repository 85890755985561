@use '../config' as *;

body {
  overflow-x: hidden;
}

// Hover States
.hover\:color-primary {
  &:hover {
    color: var(--color-primary);
  }
}

.hover\:spin {
  .spin-text {
    transform: rotate(-7deg);
    transition: all cubic-bezier(0,1,.1,1) .3s;
  }
  svg {
    transition: all ease-in-out .3s;
  }
  &:hover {
    .spin-text {
      transform: rotate(353deg);
    }
    svg {
      transform: rotate(-7deg);
    }
  }
}

.filter\:drop-shadow {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, .1));
}



.translate-y-100\% {
  transform: translateY(99%);
}
.-translate-y-100\% {
  transform: translateY(-99%);
}
.translate-x-100\% {
  transform: translateX(100%);
}
.-translate-x-100\% {
  transform: translateX(-100%);
}



// Image Color Filters
.filter-black {
    filter: brightness(0);
}
.filter-white {
  filter: brightness(0) invert(1);
}



// Background Heights
.bg-25\% {
  background: linear-gradient(to bottom, var(--color-bg) 25%, transparent 25%);
}

.bg-30\% {
  background: linear-gradient(to bottom, var(--color-bg) 30%, transparent 30%);
}

.bg-35\% {
  background: linear-gradient(to bottom, var(--color-bg) 35%, transparent 35%);
}

.bg-40\% {
  background: linear-gradient(to bottom, var(--color-bg) 40%, transparent 40%);
}

.bg-45\% {
  background: linear-gradient(to bottom, var(--color-bg) 45%, transparent 45%);
}

.bg-50\% {
  background: linear-gradient(to bottom, var(--color-bg) 50%, transparent 50%);
}

.bg-55\% {
  background: linear-gradient(to bottom, var(--color-bg) 55%, transparent 55%);
}

.bg-60\% {
  background: linear-gradient(to bottom, var(--color-bg) 60%, transparent 60%);
}

.bg-65\% {
  background: linear-gradient(to bottom, var(--color-bg) 65%, transparent 65%);
}

.bg-70\% {
  background: linear-gradient(to bottom, var(--color-bg) 70%, transparent 70%);
}

.bg-75\% {
  background: linear-gradient(to bottom, var(--color-bg) 75%, transparent 75%);
}

.bg-80\% {
  background: linear-gradient(to bottom, var(--color-bg) 80%, transparent 80%);
}

.bg-85\% {
  background: linear-gradient(to bottom, var(--color-bg) 85%, transparent 85%);
}

.bg-90\% {
  background: linear-gradient(to bottom, var(--color-bg) 90%, transparent 90%);
}

.bg-95\% {
  background: linear-gradient(to bottom, var(--color-bg) 95%, transparent 95%);
}

.buttonlink {
  text-decoration: none;
  position: relative;
  font-weight: 700;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 3px;
    border-radius: 2px;
    background: var(--color-primary);
    bottom: 5px;
    left: 0;
    transition: all ease-in-out .3s;
  }

  &:hover:after {
    width: 100%;
  }
}