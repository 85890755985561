@use '../config' as *;

/* -------------------------------- 

File#: _1_feature-v11
Title: Feature v11
Descr: Feature section containing a boxed content over a background image
Usage: codyhouse.co/license

-------------------------------- */

.feature-v11 {
  position: relative;
  z-index: 1;
}

.feature-v11__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: alpha(var(--color-contrast-higher), 0.1);
  color: var(--color-contrast-higher);
  text-decoration: none;
  line-height: 1;
  transition: 0.3s;

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);

    .feature-v11__link-icon {
      > * {
        transition: transform 0.3s var(--ease-out);
      }

      > *:first-child {
        transform: rotate(-180deg);
      }

      > *:last-child {
        transform: rotate(180deg);
      }
    }
  }
}

.feature-v11__link-icon { /* animated arrow */
  --size: 16px;

  > * {
    will-change: transform;
  }

  > *:first-child {
    transform-origin: 8px 5px;
  }

  > *:last-child {
    transform-origin: 8px 11px;
  }
}