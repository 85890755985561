@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/global/typography' as *;

// Custom Styles 👇🏻
.component-text-media {
  @extend .text-component;

  img:not(.radius-0) {
    border-radius: var(--radius-md);
  }
}