@use '../config' as *;
@use '../global/util' as *;
@use '../global/forms' as *;
@use '../global/buttons' as *;

.wpcf7-submit {
  @extend .btn;
  @extend .btn--primary;
}

input[type=text],
input[type=email],
input[type=url],
input[type=tel],
input[type=number],
input[type=date],
textarea {
  &.wpcf7-form-control {
    @extend .form-control;
  }
}

.wpcf7 {
  form {
    .wpcf7-response-output {
      margin: var(--space-md) 0 0 0;
      border: none;
      border-radius: var(--radius-md);
      padding: var(--space-sm) var(--space-md);
    }

    &.sent {
      .wpcf7-response-output {
        background: alpha(var(--color-success), .35);
        border: 2px solid var(--color-success);
      }
    }
    &.invalid {
      .wpcf7-response-output {
        background: alpha(var(--color-warning), .35);
        border: 2px solid var(--color-warning);
      }
    }
  }

  .wpcf7-not-valid-tip {
    @extend .bg-error;
    @extend .bg-opacity-20\%;
    @extend .padding-xs;
    @extend .radius-md;
    @extend .text-sm;
    @extend .color-contrast-higher;
    @extend .margin-top-2xs;
  }

  label {
    @extend .form-label;
  }
}