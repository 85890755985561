@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻

// Custom Styles 👇🏻
.badge-force-free-training-v2 {
  position: absolute;

  @include max-width(md) {
    top: 0;
    right: 0;
    transform: translate(25%, -50%) rotate(-15deg);
  }
  @include min-width(md) {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%) rotate(-15deg);
  }
}