@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_card-v2' as *;
@use '../../src/sass/components/_2_anim-cards' as *;

// Custom Styles 👇🏻
.anim-cards {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 100px 0 var(--color-bg);
    z-index: 2;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -100px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 100px 0 var(--color-bg);
    z-index: 2;
  }
}