@use '../config' as *;
@use '_1_anim-menu-btn.scss' as *;
@use '_1_modal-window.scss' as *;
@use '_2_full-screen-search.scss' as *;

/* -------------------------------- 

File#: _3_main-header-v2
Title: Main Header v2
Descr: Accessible website navigation w/ sub navigations
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --header-v2-height: 100px;

  @include min-width(lg) {
    --header-v2-height: 100px;
  }
}

.header-v2 {
  width: 100%;
}

.header-v2--expanded {
  // class added when navigation is visible - small devices only
}

.header-v2, .header-v2__wrapper {
  position: relative;
  z-index: 999;
  height: var(--header-v2-height);
}

.header-v2__container {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.header-v2__logo {
  flex-shrink: 0;

  a, svg, img {
    display: block;
    width: auto;

    @include max-width(md) {
      height: 50px;
    }
    @include min-width(md) {
      height: 65px;
    }
  }
}

.header-v2__container {
  @include max-width(lg) {
    width: 100% !important;
    padding-left: var(--space-md);
    padding-right: var(--space-md);
  }
}

.header-v2__nav-control { // menu button - visible only on small screens
  --anim-menu-btn-size: 40px;
  // 🍔 icon
  --anim-menu-btn-icon-size: 24px;
  --anim-menu-btn-icon-stroke: 2px;
}

.header-v2__nav { // main navigation
  top: var(--header-v2-height);
  left: 0;
  width: 100%;
  padding: var(--space-sm);
  background-color: var(--color-bg-light);
  box-shadow: var(--inner-glow), var(--shadow-md);
  z-index: var(--z-index-popover, 5); 
  
  // hide
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 0;
  overflow: hidden;
  visibility: hidden;

  @include max-width(lg) {
    padding-top: var(--space-lg);
    padding-bottom: var(--space-lg);
  }
}

.header-v2__nav--is-visible { // show nav
  clip: auto;
  clip-path: none;
  height: auto;
  max-height: calc(100vh - var(--header-v2-height) - var(--space-md));
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  visibility: visible;
}

.header-v2__nav-list--main:nth-child(2) { // horizontal divider between main navs
  display: flex;
  justify-content: center;
  margin-top: var(--space-md);
}

.header-v2__nav-item {
  .btn {
    font-size: var(--text-md);
    margin: var(--space-2xs) 0;
  }
}

.header-v2__nav-item--main {
  margin-bottom: var(--space-sm);
}

.header-v2__nav-item--label {
  text-transform: uppercase;
  font-size: 0.6rem;
  letter-spacing: 0.1em;
  color: var(--color-contrast-medium);
  padding: var(--space-2xs) 0;
}

.header-v2__nav-item--divider { // list divider
  height: 1px;
  background-color: var(--color-contrast-lower);
  margin: var(--space-md) 0;
}

.header-v2__nav-item--search-btn {
  display: none; // hide search btn on small devices
}

.header-v2__nav-dropdown-icon {
  display: none; // hide arrow icon on small devices
}

.header-v2__nav-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  position: relative;

  &:hover,
  &[aria-current] {
    &:not(.btn):before {
      content: url('../img/menu-indicator.svg');
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: -10px;
      width: 20px;
      height: 9px;
    }
  }

  &:not(.btn) {
    padding: var(--space-xs) 0;
    color: var(--color-contrast-high); // nav links color

    &:hover, &[aria-current] {
      color: var(--color-contrast-higher);
    }
  }

  &[aria-current] {
    font-weight: bold;
  }

  &.btn {
    padding: var(--space-2xs) var(--space-sm);
  }
}

.header-v2__nav-dropdown {
  padding-left: var(--space-sm); // sub items offset on small devices
}

// nested <ul> v1 -> title + description w/ optional icon
.header-v2__nav-list--title-desc {
  .header-v2__nav-link {
    display: flex;

    .header-v2__nav-icon {
      margin-right: var(--space-xs);
      flex-shrink: 0;
    }

    strong { // title
      display: block;
    }

    small { // description
      color: var(--color-contrast-medium);
    }

    &:hover {
      small {
        color: var(--color-contrast-high);
      }
    }
  }
}

.header-v2__nav-col-2 { // utility class -> create 2 columns
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--space-sm);
}

// change header style on bigger screens
@include min-width(lg) {
  .header-v2[data-animation="on"] .header-v2__wrapper--is-fixed {
    position: fixed;
    top: calc(-1 * var(--header-v2-height));
    left: 0;
    width: 100%;
    background-color: var(--color-bg);
    z-index: var(--z-index-fixed-element, 10); 
    transition: transform .2s;
  }

  .header-v2[data-animation="on"] .header-v2__wrapper--slides-down {
    transform: translateY(100%);
    box-shadow: var(--shadow-sm);
  }

  .header-v2__nav-control {
    display: none; // hide menu btn
  }

  .header-v2__logo {
    margin-right: var(--space-md);
  }

  .header-v2__nav {
    // make main nav visible
    position: static;
    clip: auto;
    clip-path: none;
    height: auto;
    max-height: none;
    overflow: visible;
    overscroll-behavior: auto;
    visibility: visible;

    // reset style
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;

    display: flex;
    justify-content: space-between;
  }

  .header-v2__nav-list--main { // left/right navs
    display: flex;
    align-items: center;
  }

  .header-v2__nav-item {
    position: relative;

    .btn { // reset btn style
      width: auto;
      font-size: 1.125rem;
      margin: 0;
    }
  }

  .header-v2__nav-item--main { // main nav items
    display: inline-block;
    margin-bottom: 0; // reset
    margin-left: var(--space-md); // margin between main nav items

    > .header-v2__nav-link {
      font-size: 1rem;

      &:not(.btn) {
        padding: var(--space-2xs) 0;
      }
    }

    &.header-v2__nav-item--divider {
      margin-top: 0; // reset
      margin-bottom: 0; // reset
      margin-right: 0; // reset
      width: 1px;
      height: 1em;
    }
  }

  .header-v2__nav-list--main:last-child {
    // remove horizontal separation between navs
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  .header-v2__nav-dropdown-icon {
    display: block; // arrow icon = visible
  }

  .header-v2__nav-item--search {
    display: none; // hide search input
  }

  .header-v2__nav-item--search-btn {
    display: inline-block; // show search btn

    button, .icon {
      display: block;
    }

    button {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: opacity .2s;
      cursor: pointer;

      &:hover {
        opacity: 0.65;
      }
    }
  }

  .header-v2__nav-dropdown { // dropdown menu
    --space-unit: 1rem; // set space unit = 1rem
    position: absolute;
    top: 69px;
    left: calc(50% - 100px);
    z-index: var(--z-index-popover, 5); 
    width: 200px;
    background-color: var(--color-bg-light);
    //border-radius: var(--radius-sm);
    //box-shadow: var(--inner-glow), var(--shadow-md);
    padding: 0;
    transition: opacity .2s, visibility .2s;

    // hide
    visibility: hidden;
    opacity: 0;

    .header-v2__nav-link, .header-v2__nav-item--label {
      padding-left: var(--space-xs);
      padding-right: var(--space-xs);
    }

    .header-v2__nav-link:hover,
    .header-v2__nav-link--hover {
      background-color: alpha(var(--color-contrast-higher), 0.075);
    }

    .header-v2__nav-dropdown--nested { // sub dropdown
      left: 100%;
      top: 0;
    }

    .header-v2__nav-dropdown--nested-left {
      left: auto;
      right: 100%;
    }

    .header-v2__nav-item--divider {
      margin: var(--space-xs) 0;
    }
  }

  .header-v2__nav-dropdown--sm {
    width: 200px;
    left: calc(50% - 100px);
  }

  .header-v2__nav-dropdown--md {
    width: 300px;
    left: calc(50% - 150px);
  }

  .header-v2__nav-dropdown--lg {
    width: 480px;
    left: calc(50% - 240px);
  }

  .header-v2__nav-list--title-desc {
    .header-v2__nav-link {
      padding: var(--space-xs) var(--space-sm);
    }
  }

  .header-v2__nav-list--is-visible,
  .header-v2__nav-item--has-children:hover > .header-v2__nav-dropdown { // show dropdown
    visibility: visible;
    opacity: 1;
  }
}