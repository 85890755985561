@use '../config' as *;

/* -------------------------------- 

File#: _1_back-to-top
Title: Back to top
Descr: Component that triggers scroll-y to the top of the page
Usage: codyhouse.co/license

-------------------------------- */

.back-to-top {
  --size: 50px;
  width: var(--size);
  height: var(--size);
  display: flex;

  position: fixed;
  right: var(--space-md);
  bottom: var(--space-md);
  z-index: var(--z-index-fixed-element, 10); 

  border-radius: 50%;
  background-color: alpha(var(--color-contrast-higher), 0.9);
  transition: .2s;

  visibility: hidden;
  opacity: 0;
  
  &:hover {
    background-color: var(--color-contrast-higher);
  }

  .icon {
    --size: 20px;
    display: block;
    margin: auto;
    color: var(--color-bg);
  }
}

.back-to-top--is-visible { // show button
  visibility: visible;
  opacity: 1;
}