@use '../config' as *;
@use '_1_radio-switch.scss' as *;
@use '_1_drawer.scss' as *;
@use '_1_search-input.scss' as *;
@use '_1_anim-menu-btn.scss' as *;

/* -------------------------------- 

File#: _2_drawer-navigation
Title: Drawer Navigation
Descr: Header template with drawer navigation
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --dr-nav-header-height: 54px;
  --dr-nav-logo-size: 48px;
}

@include min-width(md) {
  :root {
    --dr-nav-header-height: 70px;
    --dr-nav-logo-size: 54px;
  }
}

// header
.dr-nav-header {
  height: var(--dr-nav-header-height);
  position: relative;
  z-index: var(--z-index-header, 3); 

  .radio-switch {
    // style
    --radio-switch-width: 72px;
    --radio-switch-height: 32px;
    --radio-switch-padding: 3px;
    --radio-switch-radius: 50em;

    // animation
    --radio-switch-animation-duration: 0.3s;
  }

  .radio-switch__marker {
    background-color: var(--color-contrast-high);
  }

  .radio-switch__input:checked ~ .radio-switch__label {
    color: var(--color-bg); // icon color active
  }
}

// logo
.dr-nav-header__logo {
  display: block;
  position: absolute;
  left: calc(50% - var(--dr-nav-logo-size)/2);
  top: calc(50% - var(--dr-nav-logo-size)/2);
  width: var(--dr-nav-logo-size);
  height: var(--dr-nav-logo-size);
  text-decoration: none;
  color: var(--color-contrast-high); // logo color
  transition: .3s;

  svg {
    display: block;
    width: inherit;
    height: inherit;
  }

  &:hover {
    color: var(--color-contrast-higher);
  }
}

// nav control - menu button
.dr-nav-control-wrapper {
  position: fixed;
  z-index: var(--z-index-overlay, 15); 
  top: 0;
  left: 0;
  height: var(--dr-nav-header-height);
  width: 100%;
  pointer-events: none;
}

.dr-nav-control {
  --anim-menu-btn-size: 48px;
  // 🍔 icon
  --anim-menu-btn-icon-size: 24px;
  --anim-menu-btn-icon-stroke: 2px;
  pointer-events: auto;
  position: relative;
  cursor: pointer;
  border-radius: 50%;
  transition: transform .3s var(--ease-in-out);
  color: var(--color-contrast-high); // icon color

  circle {
    display: none;
  }

  &.anim-menu-btn--state-b {
    transform: rotate(180deg);
  }

  &:hover .dr-nav-control__bg {
    fill: var(--color-contrast-lower);
    opacity: 1;
  }
}

.dr-nav-control__bg { // SVG used to set background color and animated stroke
  display: block;
  fill: var(--color-bg); // button background color
  stroke-width: 1.5;
  stroke: var(--color-contrast-low); // stroke color
  opacity: 0.95;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: .2s;
}

.dr-nav-control--ready-to-animate circle {
  display: block;
  transition: stroke-dashoffset 0.3s 0s;
}

.dr-nav-control.anim-menu-btn--state-b circle {
  stroke-dashoffset: 0;
  transition-delay: 0.3s;
}

// drawer
.dr-nav-drawer-header {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: var(--dr-nav-header-height);
}

.dr-nav__link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  padding: var(--space-3xs) 0;
  color: var(--color-contrast-medium);
  transition: .3s;

  :nth-child(2)::before {
    content: '';
    display: inline-block;
    height: 1px;
    width: 32px;
    margin-right: var(--space-xs);
    background-color: currentColor;
    vertical-align: middle;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform .3s var(--ease-out);
  }

  &:hover {
    color: var(--color-contrast-high);

    :nth-child(2)::before {
      transform: scaleX(1);
    }
  }
}