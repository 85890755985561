@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_hero' as *;

// Custom Styles 👇🏻
.hero {
  position: relative;
  display: flex;
  align-items: center;

  @include max-width(md) {
    min-height: 500px;
  }

  @include min-width(md) {
    min-height: 700px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: .4;

    @include max-width(md) {
      width: 100%;
      background: rgb(0,0,0);
    }

    @include min-width(md) {
      width: 50%;
      background: rgb(0,0,0);
      background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    }
  }

  .hero__background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 0;

    .hero__background-image {
      position: absolute;
      height: 100%;
      width: 100%;
      user-select: none;
      user-focus: none;
      -webkit-user-drag: none;

      &.top-left {
        object-position: top left;
      }
      &.top-center {
        object-position: top center;
      }
      &.top-right {
        object-position: top right;
      }
      &.center-left {
        object-position: center left;
      }
      &.center-center {
        object-position: center center;
      }
      &.center-right {
        object-position: center right;
      }
      &.bottom-left {
        object-position: bottom left;
      }
      &.bottom-center {
        object-position: bottom center;
      }
      &.bottom-right {
        object-position: bottom right;
      }
    }
  }

  .hero__content-wrapper {
    position: relative;
    z-index: 1;
  }
}

.hero--overlay-layer {
  .container {
    z-index: 2;
  }
}