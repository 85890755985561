@use '../../src/sass/config' as *;

// Import Component Base Styles 👇🏻
@use '../../src/sass/components/_1_ticker' as *;

.component-logo-ticker {
  --ticker-animation-duration: 20s;
  --ticker-gap-x: var(--space-sm);
  --ticker-img-width: 200px;

  @include min-width(sm) {
    --ticker-img-width: 200px;
  }

  @include min-width(md) {
    --ticker-img-width: 200px;
  }

  position: relative;
}