@use '../config' as *;

/* -------------------------------- 

File#: _1_pre-header
Title: Pre-header 
Descr: Pre-header (top) banner
Usage: codyhouse.co/license

-------------------------------- */

.pre-header {
  display: block;
  background-color: var(--color-contrast-higher);
  color: var(--color-bg);
}

.pre-header--hide {
  display: none;
}

.pre-header__close-btn {
  position: absolute;
  right: 0;
  top: calc(50% - 0.5em);
  will-change: transform;
  transition: .3s var(--ease-out-back);

  &:hover {
    transform: scale(1.1);
  }

  .icon {
    display: block;
  }
}

// --link
a.pre-header {
  text-decoration: none;
  transition: background-color .2s;

  &:hover {
    text-decoration: underline;
    background-color: var(--color-contrast-high);
  }
}