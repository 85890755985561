@use '../config' as *;

/* -------------------------------- 

File#: _1_text-points
Title: Text Points
Descr: A list of text components
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --text-points-bullet-size: 32px; // bullet (circle)
  --text-points-bullet-font-size: 14px;
  --text-points-bullet-margin-right: var(--space-xs);
}

.text-points {}

.text-points--counter,
.text-points--letter,
.text-points--icon {
  .text-points__text {
    padding-left: calc(var(--text-points-bullet-size) + var(--text-points-bullet-margin-right));
  }

  .text-points__bullet {
    position: relative;
    margin-left: calc(-1 * var(--text-points-bullet-size));
    left: calc(-1 * var(--text-points-bullet-margin-right));
  
    &::before { // trick used to align bullet and text
      content: 'X';
      display: inline-flex;
      width: var(--text-points-bullet-size);
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
    }
  
    &::after { // bullet
      content: '';
  
      display: flex;
      align-items: center;
      justify-content: center;
  
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
  
      width: var(--text-points-bullet-size);
      height: var(--text-points-bullet-size);
      background-color: var(--color-bg-dark);
      border-radius: 50%;
  
      font-size: var(--text-points-bullet-font-size);
      font-weight: 600;
      color: var(--color-contrast-medium);
    }
  }
}

// --counter -> bullet = number
.text-points--counter {
  .text-points__item {
    counter-increment: text-points; // used to set the counter content
  }

  .text-points__bullet {
    &::after {
      content: counter(text-points);
    }
  }
}

// --letter -> bullet = letter
.text-points--letter {
  .text-points__bullet {
    &::after {
      content: '?';
    }
  }
}

// --icon -> bullet = icon
.text-points--icon {
  .text-points__bullet {
    &::after {
      content: '';
      mask: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='%23000000'%3E%3Ccircle cx='16' cy='16' r='16' opacity='.15'%3E%3C/circle%3E%3Cpath d='M16 24a1 1 0 0 1-1-1v-9a1 1 0 0 1 2 0v9a1 1 0 0 1-1 1z'%3E%3C/path%3E%3Ccircle cx='16' cy='9.5' r='1.5'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
      background: var(--color-primary); // icon color
    }
  }
}