@use '../config' as *;

.section {
  position: relative;

  .section__background-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden;
    z-index: 0;

    .section__background-image {
      position: absolute;
      height: 100%;
      width: 100%;
      user-select: none;
      user-focus: none;
      -webkit-user-drag: none;

      &.top-left {
        object-position: top left;
      }
      &.top-center {
        object-position: top center;
      }
      &.top-right {
        object-position: top right;
      }
      &.center-left {
        object-position: center left;
      }
      &.center-center {
        object-position: center center;
      }
      &.center-right {
        object-position: center right;
      }
      &.bottom-left {
        object-position: bottom left;
      }
      &.bottom-center {
        object-position: bottom center;
      }
      &.bottom-right {
        object-position: bottom right;
      }
    }
  }

  .section__content-wrapper {
    position: relative;
    z-index: 1;
  }
}