@use '../config' as *;

/* -------------------------------- 

File#: _1_expandable-search
Title: Expandable Search
Descr: A search button that expands to reveal a search input element
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --expandable-search-size-compact: 2.2em; /* height and width - compact version /  height - expanded version */
  --expandable-search-size-expanded: 10em; /* width - expanded version */
  --expandable-search-radius: 50em; /* border radius */
  --expandable-search-icon-size: 1.2em; /* lens icon size */
  --expandable-search-btn-padding: 2px; /* gap between button and input element */
}

.expandable-search {
  position: relative;
  display: inline-block;
  user-select: none;
}

.expandable-search__input {
  width: var(--expandable-search-size-compact);
  height: var(--expandable-search-size-compact);
  color: transparent;
  overflow: hidden;
  border-radius: var(--expandable-search-radius);
  transition: width .3s var(--ease-out), box-shadow .3s, background-color .3s;

  &::placeholder {
    opacity: 0;
    color: transparent;
  }

  &:hover {
    background-color: alpha(var(--color-contrast-higher), 0.1);
    cursor: pointer;
  }

  &:not(:focus):not(.expandable-search__input--has-content) {
    padding: 0px; /* padding = 0 if search is not expanded */
  }

  &:focus, &.expandable-search__input--has-content {
    background-color: var(--color-bg);
    width: var(--expandable-search-size-expanded);
    padding-top: 0;
    padding-right: calc(var(--expandable-search-btn-padding, 2px) + var(--expandable-search-size-compact));
    padding-left: var(--space-sm);
    padding-bottom: 0;
    outline: none;
    box-shadow: var(--shadow-md), 0 0 0 2px var(--color-primary);
    color: var(--color-contrast-higher);
    cursor: auto;
    user-select: auto;

    &::placeholder {
      opacity: 1;
      color: var(--color-contrast-low);
    }
  }

  &:focus + .expandable-search__btn {
    pointer-events: auto;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration { 
    display: none; 
  }
}

.expandable-search__btn {
  position: absolute;
  display: flex;
  top: var(--expandable-search-btn-padding, 2px);
  right: var(--expandable-search-btn-padding, 2px);
  width: calc(var(--expandable-search-size-compact) - var(--expandable-search-btn-padding, 2px)*2);
  height: calc(var(--expandable-search-size-compact) - var(--expandable-search-btn-padding, 2px)*2);
  border-radius: var(--expandable-search-radius);
  z-index: 1;
  pointer-events: none;
  transition: background-color 0.3s;

  .icon {
    display: block;
    margin: auto;
    height: var(--expandable-search-icon-size);
    width: var(--expandable-search-icon-size);
  }

  &:hover {
    background-color: alpha(var(--color-contrast-higher), 0.1);
  }

  &:focus {
    outline: none;
    background-color: alpha(var(--color-primary), 0.15);
  }
}