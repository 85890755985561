@use '../config' as *;

/* -------------------------------- 

File#: _1_search-input
Title: Search input
Descr: Search input field with custom button
Usage: codyhouse.co/license

-------------------------------- */

:root {
  --search-input-btn-width: 2.2em;
  --search-input-icon-size: 1em;
  --search-input-shortcut-margin: 0.325em; /* gap between the shortcut badge and the input edges */
}

.search-input {
  position: relative;
}

.search-input__input {
  width: 100%;
  height: 100%;
  
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0; 
  }

  .search-input--icon-right & {
    padding-right: var(--search-input-btn-width);
  }

  .search-input--icon-left & {
    padding-left: var(--search-input-btn-width);
  }
}

.search-input__btn {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: var(--search-input-btn-width);

  &:active .icon {
    transform: translateY(2px);
  }

  .icon {
    display: block;
    --size: var(--search-input-icon-size);
    margin-left: auto;
    margin-right: auto;
    color: var(--color-contrast-low); /* icon color */
    transition: .2s;
  }

  .search-input--icon-left & {
    left: 0;
    right: auto;
    pointer-events: none;
  }
}

.search-input__btn:focus .icon,
.search-input .search-input__input:focus + .search-input__btn .icon {
  color: var(--color-primary); /* active icon color */
}

/* --shortcut */
.search-input__shortcut {
  position: absolute;
  right: var(--search-input-shortcut-margin);
  top: var(--search-input-shortcut-margin);
  height: calc(100% - var(--search-input-shortcut-margin)*2);
  display: flex;
  align-items: center;

  background-color: var(--color-bg);
  border: 1px solid var(--color-contrast-lower);
  border-radius: var(--radius-sm);

  --space-unit: 1em;
  padding: 0 var(--space-3xs);

  line-height: 1;
  color: var(--color-contrast-medium);
}

.search-input:focus-within .search-input__shortcut {
  display: none;
}