@use '../config' as *;

.socials {
  position: fixed;
  z-index: 99;
  display: flex;
  gap: 10px;

  @include max-width(md) {
    flex-direction: row;
    bottom: var(--space-md);
    left: 50%;
    transform: translateX(-50%);
  }

  @include min-width(md) {
    flex-direction: column;
    bottom: var(--space-md);
    right: var(--space-md);
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: var(--color-primary);

    svg {
      display: block;
      width: 25px;
      height: 25px;
      color: var(--color-white);
      fill: currentColor
    }
  }
}